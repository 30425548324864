import GlobalStyles from '@iso/assets/styles/globalStyle';
import React from 'react';
import { Provider } from 'react-redux';

import AppProvider from './AppProvider';
import Boot from './redux/boot';
import { store } from './redux/store';
import Routes from './router';

const App = () => (
  <>
    <div
      style={{
        width: '100%',
        height: '5px',
        backgroundColor: '#88ff73',
      }}
    ></div>
    <Provider store={store}>
      <AppProvider>
        <>
          <GlobalStyles />
          <Routes />
        </>
      </AppProvider>
    </Provider>
  </>
);
Boot()
  .then(() => App())
  .catch(error => console.error(error));

export default App;
