import React, { lazy, Suspense } from 'react';
import {
  Route,
  Redirect,
  BrowserRouter as Router,
  Switch,
  useLocation,
} from 'react-router-dom';
import { useSelector } from 'react-redux';

import ErrorBoundary from './ErrorBoundary';
import { PUBLIC_ROUTE } from './route.constants';
import Loader from '@iso/components/utility/loader';
const DashboardADM = lazy(() => import('./containers/DashboardADM/Dashboard'));
const DashboardPartner = lazy(() =>
  import('./containers/DashboardPartner/Dashboard')
);

const NoMatch = lazy(() => import('./containers/Pages/404/404'));

const publicRoutes = [
  {
    path: PUBLIC_ROUTE.LANDING,
    exact: true,
    component: lazy(() => import('@iso/containers/Pages/SignIn/SignIn')),
  },
  {
    path: PUBLIC_ROUTE.SIGN_IN,
    component: lazy(() => import('@iso/containers/Pages/SignIn/SignIn')),
  },
  {
    path: '/termos-uso-parceiro',
    component: lazy(() => import('@iso/containers/Pages/TermsAndPolicy')),
  },
  {
    path: '/primeiro-acesso',
    component: lazy(() => import('@iso/containers/Pages/ChangePassword')),
  },
  {
    path: PUBLIC_ROUTE.AUTH0_CALLBACK,
    component: lazy(() =>
      import('@iso/containers/Authentication/Auth0/Auth0Callback')
    ),
  },
];

//FALTA FAZER O CONTROLE DE TIPO DE USUARIO PARA AS ROTAS, CRIAR UMA ROTA PRIVADA PRA CADA TIPO
//"PrivateRouteADM" e "PrivateRouteClínica"
//armazenar o tipo de usuario no redux e fazer o controle

function PrivateRouteADM({ children, ...rest }) {
  let location = useLocation();
  const isLoggedIn = useSelector(state => state.Auth.idToken);
  const type = useSelector(state => state.Auth.tipoUsuario);
  if (isLoggedIn && type === 'ADMIN') {
    return <Route {...rest}>{children}</Route>;
  }
  return (
    <Redirect
      to={{
        pathname: '/signin',
        state: { from: location },
      }}
    />
  );
}

function PrivateRoutePartner({ children, ...rest }) {
  let location = useLocation();
  const isLoggedIn = useSelector(state => state.Auth.idToken);
  const type = useSelector(state => state.Auth.tipoUsuario);
  if (isLoggedIn && type === 'PARTNER') {
    return <Route {...rest}>{children}</Route>;
  }
  return (
    <Redirect
      to={{
        pathname: '/signin',
        state: { from: location },
      }}
    />
  );
}

export default function Routes() {
  return (
    <ErrorBoundary>
      <Suspense fallback={<Loader />}>
        <Router>
          <Switch>
            {publicRoutes.map((route, index) => (
              <Route key={index} path={route.path} exact={route.exact}>
                <route.component />
              </Route>
            ))}

            <PrivateRouteADM path='/adm'>
              <DashboardADM />
            </PrivateRouteADM>

            <PrivateRoutePartner path='/parceiro'>
              <DashboardPartner />
            </PrivateRoutePartner>

            <Route component={NoMatch} />
          </Switch>
        </Router>
      </Suspense>
    </ErrorBoundary>
  );
}
